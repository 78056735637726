import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import toast from 'react-hot-toast';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import DropzoneContainer from '../common/DropzoneContainer';
import ProcessingButtons from '../components/ProcessingButtons';
import { formatReportData, sendPostRequest, sendReport } from '../util/api';

import cuid from 'cuid';
import { MAX_FILE_SIZE } from '../consts';
import {
  SLIDER_PARAMETERS,
  MODEL_SPECIFIC_SLIDER_PARAMETERS,
  ParameterDefinitions,
} from './const';
import AreaGrid from '../common/area-grid/AreaGrid';
import Area from '../common/area-grid/Area';
import Row from '../common/area-grid/Row';
import { FormattedMessage } from 'react-intl';
import ToggleSwitch from '../common/ToggleSwitch';
import DeleteIcon from '../assets/icons/delete.svg';
import { AudioData } from '../common/types';
import ErrorToast from '../common/ErrorToast';
import ReportButton, { ReportData } from '../common/ReportButton';
import AudioCard from '../common/AudioCard';
import { convertWavStringToBlob } from '../util/audio';

interface AudioResultGroup {
  id: string;
  date: Date;
  text: string;
  results: AudioResult[];
}

interface AudioResult {
  id: string;
  audioData: Blob;
  formDataJson?: Record<string, FormDataEntryValue>;
  embedJson?: object;
}

interface InterpolationParams {
  api: string;
  model_type: string;
  embed_extractor_model_type: string;
  tts_model_type: string;
  interpolation_method: string;
  tgt: File[];
  uuids: string[];
  timbre_weights: number[];
  style_weights: number[];
  text: string;
  use_stable: boolean;
  lang: string;
  speed: number;
  pitch_shift: number;
  pitch_variance: number;
  similarity: number;
  text_guidance?: number;
  nfe?: number;
  duration: number;
  solver?: number;
  pitch_confidence_threshold?: number;
}

const INTERPOLATION_METHODS = [
  'spherical',
  'linear',
  'normalized_linear',
  'adaptive',
];
type MODEL_TYPES = 'v1.2.1' | 'v2.1.0';
type ModelInfo = {
  label: string;
};
const modelInfoMap: Record<MODEL_TYPES, ModelInfo> = {
  'v1.2.1': {
    label: 'v1.2.1',
  },
  'v2.1.0': {
    label: 'v2.1.0',
  },
};

const modelTypes = Object.keys(modelInfoMap) as MODEL_TYPES[];

const getDefaultSliderValues = (model: string) => {
  const baseValues = SLIDER_PARAMETERS;
  const modelSpecificValues = MODEL_SPECIFIC_SLIDER_PARAMETERS[model] || {};

  return {
    pitch_shift:
      modelSpecificValues.pitch_shift?.defaultValue ??
      baseValues.pitch_shift.defaultValue,
    pitch_variance:
      modelSpecificValues.pitch?.defaultValue ?? baseValues.pitch.defaultValue,
    speed:
      modelSpecificValues.speed?.defaultValue ?? baseValues.speed.defaultValue,
    similarity:
      modelSpecificValues.similarity?.defaultValue ??
      baseValues.similarity.defaultValue,
    text_guidance: 1.0,
    nfe: model === 'v2.1.0' ? 12 : 8,
    solver: model === 'v2.1.0' ? 0 : 2,
  };
};

const API_PATH = process.env.REACT_APP_API_PATH_TTS_INTERPOLATION!;

const TEXT_MAX_LENGTH_MAP = {
  'en-us': 1000,
  ko: 500,
};

const TtsInterpolationPage = () => {
  const [selectedModel, setSelectedModel] = useState<MODEL_TYPES>('v2.1.0');
  const [interpolationMethod, setInterpolationMethod] =
    useState<string>('linear');
  const [tgtFiles, setTgtFiles] = useState<FileWithPath[]>([]);
  const [uuids, setUuids] = useState<string[]>([]);
  const [text, setText] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [results, setResults] = useState<AudioResultGroup[]>([]);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [audioDataList, setAudioDataList] = useState<AudioData[]>([]);
  const [language, setLanguage] = useState('en-us');
  const [useStable, setUseStable] = useState(true);
  const [useDuration, setUseduration] = useState(false);
  const [isError, setIsError] = useState(false);
  const [duration, setDuration] = useState(0.0);
  const [textGuidance, setTextGuidance] = useState(1.0);
  const [threshold, setThreshold] = useState(0.8);
  const [solver, setSolver] = useState(2);

  const [sliderValues, setSliderValues] = useState<
    Partial<Record<string, number | undefined>>
  >(() => getDefaultSliderValues(selectedModel));

  useEffect(() => {
    if (tgtFiles.length > 0) {
      setUuids(tgtFiles.map(() => cuid()));
    }
  }, [tgtFiles]);

  useEffect(() => {
    setSliderValues(getDefaultSliderValues(selectedModel));
    setSolver(selectedModel === 'v2.1.0' ? 0 : 2);
  }, [selectedModel]);

  const getRequestData = (): InterpolationParams => ({
    api: 'tts',
    model_type: 'tts-interpolation',
    embed_extractor_model_type: `${selectedModel}-embed-extractor`,
    tts_model_type: selectedModel,
    interpolation_method: interpolationMethod,
    tgt: tgtFiles.map((file) => file as File),
    uuids,
    timbre_weights: tgtFiles.map(
      (_, i) => sliderValues[`timbre_weight_${i}`] ?? 1.0
    ),
    style_weights: tgtFiles.map(
      (_, i) => sliderValues[`style_weight_${i}`] ?? 1.0
    ),
    text,
    use_stable: useStable,
    lang: language,
    speed: sliderValues.speed ?? 1.0,
    pitch_shift: sliderValues.pitch_shift ?? 0,
    pitch_variance: sliderValues.pitch ?? 1.0,
    similarity: sliderValues.similarity ?? 2.0,
    text_guidance: textGuidance,
    nfe: sliderValues.nfe ?? 8,
    duration: duration,
    solver: solver,
    pitch_confidence_threshold: threshold,
  });

  const maxLength =
    TEXT_MAX_LENGTH_MAP[language as keyof typeof TEXT_MAX_LENGTH_MAP];

  const handleFileUpload = (files: FileWithPath[]) => {
    setTgtFiles((prev) => [...prev, ...files]);
    setAudioDataList((prev) => {
      const newAudioDataList: AudioData[] = files.map((file) => ({
        file: new File([file], file.name, { type: 'audio/wav' }),
        type: 'file',
        name: file.name,
      }));
      return [...prev, ...newAudioDataList];
    });
  };

  const onSubmit = async () => {
    if (tgtFiles.length < 2) {
      toast.error('최소 두 개의 오디오 파일을 업로드해주세요.');
      return;
    }

    setIsProcessing(true);
    try {
      const requestData = getRequestData();
      const group: AudioResultGroup = {
        id: cuid(),
        date: new Date(),
        text: text,
        results: [],
      };

      // Convert array parameters to indexed form
      const formData = new FormData();
      Object.entries(requestData).forEach(([key, value]) => {
        if (key === 'duration' && value === 0) {
          return;
        }

        if (Array.isArray(value)) {
          if (
            ['tgt', 'uuids', 'timbre_weights', 'style_weights'].includes(key)
          ) {
            value.forEach((item, index) => {
              formData.append(`${key}.${index}.list`, item);
            });
          } else {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}].list`, item);
            });
          }
        } else {
          formData.append(key, value.toString());
        }
      });

      const abortController = new AbortController();
      setAbortController(abortController);

      // Save formData as JSON for later download
      const formDataJson: Record<string, FormDataEntryValue> = {};
      formData.forEach((value, key) => {
        formDataJson[key] = value;
      });

      const { data } = await sendPostRequest<FormData>(
        API_PATH,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
        {
          responseType: 'arraybuffer',
        },
        abortController
      );

      setIsError(false);

      // Parse response data to get embed info
      const decoder = new TextDecoder();
      const responseText = decoder.decode(data);
      const responseJson = JSON.parse(responseText);
      const { embed, wav } = responseJson;

      const r: AudioResult = {
        id: cuid(),
        audioData: convertWavStringToBlob(wav),
        formDataJson: formDataJson,
        embedJson: embed,
      };

      group.results.push(r);

      setResults([group, ...results]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsProcessing(false);
    }
  };

  const abort = useCallback(() => {
    abortController?.abort();
  }, [abortController]);

  const getAudioUrl = (audioData: AudioData) => {
    return URL.createObjectURL(audioData.file);
  };

  const onAudioDelete = (index: number) => {
    setAudioDataList((prev) => prev.filter((_, i) => i !== index));
    setTgtFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    setText(e.target.value);
  };

  const handleReportSubmit = async ({ user, problem }: ReportData) => {
    if (!selectedModel) return;
    if (audioDataList.length === 0) return;

    const inputData = await getRequestData();
    if (!inputData) return;

    inputData.tgt = audioDataList.map((ad) => ad.file);

    const data = formatReportData({
      ...inputData,
      user,
      problem,
    });

    const r = await sendReport(data);
    return r;
  };

  const onResultDelete = (groupId: string, id: string) => () => {
    const group = results.find((group) => group.id === groupId);

    if (!group) return;

    const index = group.results.findIndex((result) => result.id === id);
    group.results.splice(index, 1);

    setResults([...results]);
  };

  const onSliderChange = (
    key: keyof ParameterDefinitions,
    value: number | number[]
  ) => {
    setSliderValues((prev) => ({
      ...prev,
      [key]: Array.isArray(value) ? value[0] : value,
    }));
  };

  // make slider sections
  const sliderSections = Object.keys(SLIDER_PARAMETERS).map((key) => {
    const keyOfParameter = key as keyof ParameterDefinitions;
    const sliderDefinition = SLIDER_PARAMETERS[keyOfParameter];
    const value = sliderValues[keyOfParameter] ?? sliderDefinition.defaultValue;
    const { minValue, maxValue } = sliderDefinition;

    const marks = [
      {
        value: minValue,
        label: `${sliderDefinition.minLabel}(${minValue})`,
      },
      {
        value: maxValue,
        label: `${sliderDefinition.maxLabel}(${maxValue})`,
      },
    ];

    return (
      <div className="parameter" key={`slider-${keyOfParameter}`}>
        <div className="inner">
          <Tooltip
            title={sliderDefinition.tooltip}
            arrow
            placement="bottom-start"
          >
            <label>{sliderDefinition.label}</label>
          </Tooltip>
          <span className="value">{value}</span>
        </div>
        <div className="slider-container">
          <Slider
            defaultValue={sliderDefinition.defaultValue}
            value={value}
            min={minValue}
            max={maxValue}
            step={sliderDefinition.step}
            marks={marks}
            onChange={(_, value) => onSliderChange(keyOfParameter, value)}
            disabled={
              key === 'speed' &&
              ['v2.1.0'].includes(selectedModel) &&
              useDuration
            }
          />
        </div>
      </div>
    );
  });

  const nfeMarks = [
    { value: 3, label: 'min(3)' },
    { value: 16, label: 'max(16)' },
  ];

  const testGuidanceMarks = [
    { value: 0.0, label: 'min(0.0)' },
    { value: 4.0, label: 'max(4.0)' },
  ];

  const thresholdMarks = [
    { value: 0.0, label: 'min(0.0)' },
    { value: 1.0, label: 'max(1.0)' },
  ];

  const downloadJson = (data: object, filename: string) => {
    const dataStr = JSON.stringify(data, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <AreaGrid className="tts">
      <Row>
        <Area
          name="main"
          size="380px"
          minSize="380px"
          maxSize="50%"
          splitterAt="left"
        >
          <div>
            <div
              className="contents"
              style={{
                height: `calc(100% - ${isProcessing ? 120 : 70}px)`,
                boxShadow: 'none',
                paddingRight: '1.5rem',
              }}
            >
              <section className="description">
                <Typography variant="h2" component="h2">
                  TTS Interpolation
                </Typography>
              </section>
              <div>
                <section>
                  <FormControl fullWidth>
                    <div className="inner">
                      <div className="title">Model Type</div>
                    </div>
                    <Select
                      className="tts-select"
                      value={selectedModel}
                      onChange={(e) =>
                        setSelectedModel(e.target.value as MODEL_TYPES)
                      }
                      fullWidth={true}
                      label=""
                      sx={{
                        marginTop: '0.5em',
                        backgroundColor: '#fff',
                      }}
                    >
                      {modelTypes.map((model) => (
                        <MenuItem key={model} value={model}>
                          {model}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </section>
                <section>
                  <FormControl fullWidth>
                    <div className="inner">
                      <div className="title">Interpolation Method</div>
                    </div>
                    <Select
                      className="tts-select"
                      value={interpolationMethod}
                      onChange={(e) =>
                        setInterpolationMethod(e.target.value as string)
                      }
                      fullWidth={true}
                      label=""
                      sx={{
                        marginTop: '0.5em',
                        backgroundColor: '#fff',
                      }}
                    >
                      {INTERPOLATION_METHODS.map((method) => (
                        <MenuItem key={method} value={method}>
                          {method}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </section>
                <section>
                  <div className="inner">
                    <div className="title">Source audio</div>
                    <Typography variant="subtitle1">
                      Only mono audio channels are supported.
                    </Typography>
                  </div>
                  <div>
                    {audioDataList.map((audioData, index) => {
                      const url = getAudioUrl(audioData);
                      return (
                        <div key={`${audioData.name}-${index}`}>
                          <div
                            className="audio-container"
                            key={`audio-${audioData.name}-${index}`}
                          >
                            <span>{audioData.name}</span>
                            <button
                              className="btn-delete"
                              onClick={() => onAudioDelete(index)}
                            >
                              <img
                                src={DeleteIcon}
                                alt="Delete source audio."
                              />
                            </button>
                            <audio
                              controls
                              id="audio-player"
                              title={audioData.name}
                              src={url}
                              key={url}
                              preload="metadata"
                            />
                          </div>
                          <div
                            className="parameter"
                            style={{
                              marginTop: '1em',
                            }}
                          >
                            <div className="inner">
                              <Tooltip
                                title={'얼마나 텍스트를 따를 것인지 정도'}
                                arrow
                                placement="bottom-start"
                              >
                                <label>Style Weight</label>
                              </Tooltip>
                              <span className="value">
                                {sliderValues[`style_weight_${index}`] ?? 1.0}
                              </span>
                            </div>
                            <div className="slider-container">
                              <Slider
                                key={`style-${index}`}
                                defaultValue={1.0}
                                value={sliderValues[`style_weight_${index}`]}
                                min={0.0}
                                max={1.0}
                                step={0.1}
                                marks={thresholdMarks}
                                onChange={(_, value) =>
                                  setSliderValues((prev) => ({
                                    ...prev,
                                    [`style_weight_${index}`]: value as number,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="parameter">
                            <div className="inner">
                              <Tooltip
                                title={'얼마나 텍스트를 따를 것인지 정도'}
                                arrow
                                placement="bottom-start"
                              >
                                <label>Timbre Weight</label>
                              </Tooltip>
                              <span className="value">
                                {sliderValues[`timbre_weight_${index}`] ?? 1.0}
                              </span>
                            </div>
                            <div className="slider-container">
                              <Slider
                                key={`timbre-${index}`}
                                defaultValue={1.0}
                                value={sliderValues[`timbre_weight_${index}`]}
                                min={0.0}
                                max={1.0}
                                step={0.1}
                                marks={thresholdMarks}
                                onChange={(_, value) =>
                                  setSliderValues((prev) => ({
                                    ...prev,
                                    [`timbre_weight_${index}`]: value as number,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <DropzoneContainer
                    successCallback={handleFileUpload}
                    maxSize={MAX_FILE_SIZE}
                    accept={{
                      'audio/*': [],
                    }}
                  >
                    <p>
                      <UploadFileIcon fontSize="small" />
                      <FormattedMessage
                        id="dropzone"
                        defaultMessage="Drop files or click here"
                      />
                    </p>
                  </DropzoneContainer>
                </section>
                <section>
                  <div className="inner">
                    <div className="title">Text</div>
                  </div>
                  <div>
                    <textarea
                      value={text}
                      onChange={onTextChange}
                      maxLength={maxLength}
                    ></textarea>
                    {maxLength && (
                      <div className="note">
                        {maxLength.toLocaleString()} characters max
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <div className="inner">
                    <FormControl>
                      <label className="language-label">Language</label>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={language}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setLanguage(event.target.value)
                        }
                        sx={{ marginTop: '0.5rem' }}
                      >
                        <FormControlLabel
                          value="ko-nn"
                          control={<Radio />}
                          label="Korean"
                          sx={{
                            fontSize: '0.8rem',
                            color: '#3a3a3a',
                            fontWeight: 600,
                          }}
                        />
                        <FormControlLabel
                          value="en-us"
                          control={<Radio />}
                          label="English"
                          sx={{
                            fontSize: '0.8rem',
                            color: '#3a3a3a',
                            fontWeight: 600,
                          }}
                        />
                        <FormControlLabel
                          value="ja"
                          control={<Radio />}
                          label="Japanese"
                          sx={{
                            fontSize: '0.8rem',
                            color: '#3a3a3a',
                            fontWeight: 600,
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </section>
                <div className="parameter">
                  <div className="inner">
                    <Tooltip
                      title={'turbo 모델을 사용할지 여부'}
                      arrow
                      placement="bottom-start"
                    >
                      <label>Stable mode</label>
                    </Tooltip>
                    <FormControlLabel
                      control={
                        <ToggleSwitch
                          id={'input-useStable'}
                          checked={useStable}
                          onChange={() => setUseStable(!useStable)}
                        />
                      }
                      label={''}
                      sx={{
                        paddingLeft: '207px',
                      }}
                    />
                  </div>
                </div>
                {['v2.1.0'].includes(selectedModel) && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'Duration 을 사용 할지 여부'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Use Duration</label>
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <ToggleSwitch
                            id={'input-useDuration'}
                            checked={useDuration}
                            onChange={() => {
                              setUseduration(!useDuration);
                              setDuration(0.0);
                            }}
                          />
                        }
                        label={''}
                        sx={{
                          paddingLeft: '203px',
                        }}
                      />
                    </div>
                  </div>
                )}
                {['v2.1.0'].includes(selectedModel) && useDuration && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={'몇 초짜리 음원을 생성할 것인 지정합니다.'}
                        arrow
                        placement="bottom-start"
                      >
                        <label>Duration</label>
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <TextField
                            variant="outlined"
                            placeholder="Enter duration in seconds"
                            type="number"
                            fullWidth
                            value={duration}
                            onChange={(e) =>
                              setDuration(Number(e.target.value))
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginRight: '8px' }}
                                >
                                  sec
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              step: '0.1',
                              min: '0.0',
                              max: '60.0',
                            }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                height: '36px',
                                padding: '0 4px',
                              },
                            }}
                          />
                        }
                        label={''}
                        className="duration-input"
                        sx={{
                          width: '0',
                        }}
                      />
                    </div>
                  </div>
                )}
                {sliderSections}

                <div className="parameter">
                  <div className="inner">
                    <Tooltip
                      title={'얼마나 텍스트를 따를 것인지 정도'}
                      arrow
                      placement="bottom-start"
                    >
                      <label>Text Guidance</label>
                    </Tooltip>
                    <span className="value">{textGuidance}</span>
                  </div>
                  <div className="slider-container">
                    <Slider
                      defaultValue={textGuidance}
                      value={textGuidance}
                      min={0.0}
                      max={4.0}
                      step={0.1}
                      marks={testGuidanceMarks}
                      onChange={(_, value: number | number[]) => {
                        setTextGuidance(value as number);
                      }}
                    />
                  </div>
                </div>
                <div className="parameter">
                  <div className="inner">
                    <Tooltip
                      title={'몇 번에 걸쳐서 생성할지를 정합니다.'}
                      arrow
                      placement="bottom-start"
                    >
                      <label>Generation Cycle</label>
                    </Tooltip>
                    <span className="value">{sliderValues.nfe}</span>
                  </div>
                  <div className="slider-container">
                    <Slider
                      value={sliderValues.nfe ?? 8}
                      min={3}
                      max={16}
                      step={1}
                      marks={nfeMarks}
                      onChange={(_, value) =>
                        setSliderValues((prev) => ({
                          ...prev,
                          nfe: value as number,
                        }))
                      }
                    />
                  </div>
                </div>
                {['v1.2.1'].includes(selectedModel) && (
                  <div className="parameter">
                    <div className="inner">
                      <Tooltip
                        title={
                          'v1.2.1-embed-extractor 에서 사용되는 threshold 값'
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <label>Threshold</label>
                      </Tooltip>
                      <span className="value">{threshold}</span>
                    </div>
                    <div className="slider-container">
                      <Slider
                        defaultValue={threshold}
                        value={threshold}
                        min={0.0}
                        max={1.0}
                        step={0.1}
                        marks={thresholdMarks}
                        onChange={(_, value: number | number[]) => {
                          setThreshold(value as number);
                        }}
                      />
                    </div>
                  </div>
                )}
                {['v2.1.0'].includes(selectedModel) && (
                  <section>
                    <div className="inner">
                      <FormControl>
                        <Tooltip
                          title={'미분 방정식 종류를 선택합니다.'}
                          arrow
                          placement="bottom-start"
                        >
                          <label className="language-label">ODE Solver</label>
                        </Tooltip>
                        <RadioGroup
                          row
                          aria-labelledby="row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={solver}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setSolver(Number(event.target.value))
                          }
                          sx={{ marginTop: '0.5rem' }}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Euler"
                            sx={{
                              fontSize: '0.8rem',
                              color: '#3a3a3a',
                              fontWeight: 600,
                            }}
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Midpoint"
                            sx={{
                              fontSize: '0.8rem',
                              color: '#3a3a3a',
                              fontWeight: 600,
                            }}
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="rk4"
                            sx={{
                              fontSize: '0.8rem',
                              color: '#3a3a3a',
                              fontWeight: 600,
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </section>
                )}
              </div>
            </div>
            <div
              style={{
                padding: '1em',
                boxSizing: 'border-box',
              }}
            >
              <ProcessingButtons
                isDisabled={false}
                onAbort={abort}
                onSubmit={onSubmit}
                isProcessing={isProcessing}
              />
            </div>
          </div>
        </Area>

        <Area name="contents-container">
          <AreaGrid className="contents-container">
            <Row>
              <Area name="contents">
                <div className="main">
                  <section>
                    <ErrorToast
                      isOpen={isError}
                      updateIsOpen={(status: boolean) => setIsError(status)}
                    />
                  </section>
                  <section className="results-container">
                    {results.map((group) => {
                      const audioTitle = group.text
                        .replace(/\s/gim, '')
                        .slice(0, 18);

                      return (
                        <div
                          key={group.id}
                          className="result-group"
                          style={{
                            padding: '1rem',
                          }}
                        >
                          <h3 className="text" style={{ color: '#000' }}>
                            {group.text}
                          </h3>
                          {group.results.map((r) => (
                            <AudioCard
                              key={r.id}
                              title={group.text}
                              onDelete={onResultDelete(group.id, r.id)}
                              audioId="result-player"
                              audioTitle={audioTitle}
                              audioUrl={r.audioData}
                            ></AudioCard>
                          ))}
                        </div>
                      );
                    })}
                  </section>
                  <section
                    className="download-buttons"
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      margin: 0,
                      paddingLeft: '1rem',
                    }}
                  >
                    {results.length > 0 && results[0].results.length > 0 && (
                      <>
                        <Button
                          variant="outlined"
                          className="btn-audio"
                          color="primary"
                          size="small"
                          onClick={() => {
                            const formDataJson =
                              results[0].results[0].formDataJson;
                            const audioTitle = results[0].text
                              .replace(/\s/gim, '')
                              .slice(0, 18);

                            if (formDataJson) {
                              downloadJson(
                                formDataJson,
                                `parameters_${audioTitle}.json`
                              );
                            }
                          }}
                        >
                          Download Parameters
                        </Button>
                        <Button
                          variant="outlined"
                          className="btn-audio"
                          color="primary"
                          size="small"
                          onClick={() => {
                            const embedJson = results[0].results[0].embedJson;
                            const audioTitle = results[0].text
                              .replace(/\s/gim, '')
                              .slice(0, 18);

                            if (embedJson) {
                              downloadJson(
                                embedJson,
                                `embed_${audioTitle}.json`
                              );
                            }
                          }}
                        >
                          Download Embed
                        </Button>
                      </>
                    )}
                  </section>

                  <ReportButton submitCallback={handleReportSubmit} />
                </div>
              </Area>
            </Row>
          </AreaGrid>
        </Area>
      </Row>
    </AreaGrid>
  );
};

export default TtsInterpolationPage;
